
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { isoToString, friendlyDate } from '@/utils/time'
import { metersToMilesString } from '@/utils/distance'
import {
  paymentStatusTypeMap,
  referralStatusTypeMap,
} from '@/utils/reservation.js'
import { pluralize } from '@/utils/string'
import ReservationCustomerActionsDropdown from './ReservationCustomerActionsDropdown.vue'
import { paidAmenityTypeIds, PaymentTypeId, SplitFeatureFlag } from '@/utils/enum'
import customers from '@/services/customers'
import { capitalize } from '@/utils/string'
import { AmenityType, AwardedProvider, CustomerAccount, PurchaseOrder, ReferredProvider, Stop, Vehicle } from '@/models/dto'
import { Customer } from '@/models/dto/Customer'
import { currencyFilter } from '@/utils/string'
import { secondsToDaysAndHoursString } from '@/utils/time'

@Component({
  components: {
    ReservationCustomerActionsDropdown,
  },
})
export default class ReservationOverview extends Vue {
  @Prop({ type: String, default: '' }) createdOn!: string
  @Prop({ type: String, default: '' }) reservationStatus!: string
  @Prop({ type: Number }) distance!: number
  @Prop({ type: Number }) drivingTime!: number
  @Prop({ type: String }) tripType!: string
  @Prop({ type: Number }) driverCount!: number
  @Prop({ type: Number }) passengerCount!: number
  @Prop({ type: Object, default: [] }) requiredVehicles!: Vehicle[]
  @Prop({ type: Number, default: null }) reservationType!: number | null
  @Prop({ type: Array, default: [] }) referredTo: ReferredProvider[]
  @Prop({ type: Array, default: [] }) awardedTo: AwardedProvider[]
  @Prop({ type: Number, default: null }) clientTotalAdjusted: number | null
  @Prop({ type: Number }) amount: number
  @Prop({ type: Number }) balance: number
  @Prop({ type: Number, default: null }) referralPassengerCount!: number | null
  @Prop({ type: Array, default: [] }) stops!: Stop[]
  @Prop({ type: String, default: '' }) checkoutName!: string
  @Prop({ type: String, default: null }) id!: string | null
  @Prop({ type: Number, required: true }) reservationId!: number
  @Prop({ type: String, required: true }) reservationExternalId!: string
  @Prop({ type: Object, required: true }) tripAmenities: AmenityType[]
  @Prop({ type: Number, required: true }) tripId!: number
  @Prop({ type: Number, default: null }) customerId!: number | null
  @Prop({ type: Number, required: true }) tripContactId!: number
  @Prop({ type: Object, default: null }) purchaseOrder: PurchaseOrder
  @Prop({ type: Array, required: true }) purchaseOrderStatuses!: string[]
  @Prop({ type: Object, default: null }) customerAccount!: CustomerAccount
  @Prop({ type: String, default: '' }) cancellationStatusKey!: string
  @Prop({ type: String, default: null }) productClassificationLabel!: string | null
  @Prop({ type: String, default: null }) sourcingTeamClassificationLabel!: string | null
  @Prop({ type: String, default: null }) supportTeamClassificationLabel!: string | null
  @Prop({ type: Object, default: [] }) paymentMethodTypes!: any
  @Prop({ type: Object, default: [] }) balancePaymentMethods!: any
  @Prop({ type: String, default: null }) managedId!: string | null
  @Prop({ type: Number, required: true }) paymentTypeId!: number

  customer: any = {}
  tripContact: any = {}
  customerActions = [
    {
      title: 'View Customer Page',
      action: this.viewCustomer,
      id: 0,
    },
  ]
  accountActions = [
    {
      title: 'View Customer Account Page',
      action: this.viewCustomerAccount,
      id: 0,
    },
    {
      title: 'View/Update Customer Account Notes',
      action: this.editCustomerAccountNotes,
      id: 1,
    },
  ]

  capitalize = capitalize
  isoToString =isoToString
  friendlyDate = friendlyDate
  metersToMilesString = metersToMilesString
  secondsToDaysAndHoursString = secondsToDaysAndHoursString
  pluralize = pluralize
  currencyFilter = currencyFilter

  get customerFirstName(): string {
    return this.customer?.firstName
  }

  get customerLastName(): string {
    return this.customer?.lastName
  }

  get customerEmail(): string {
    return this.customer?.email
  }

  get customerPhone(): string {
    return this.customer?.phone
  }

  get tripContactFirstName(): string {
    return this.tripContact.firstName
  }

  get tripContactLastName(): string {
    return this.tripContact?.lastName
  }

  get tripContactEmail(): string {
    return this.tripContact?.email
  }

  get tripContactPhone(): string {
    return this.tripContact?.phone
  }

  get canViewOpsTotal() {
    return this.$store.getters['auth/canViewOpsTotal']
  }

  get showPurchaseOrderStatus(): boolean {
    return this.paymentTypeId === PaymentTypeId.BillAfterServices
  }

  get showPurchaseOrderEdit(): boolean {
    return this.showPurchaseOrderStatus && this.$store.getters['auth/hasPermission']('canEditPurchaseOrders')
  }

  get purchaseOrderStatus() {
    return this.purchaseOrder?.purchaseOrderStatus || null
  }

  get purchaseOrderStatusText() {
    return this.purchaseOrderStatus || 'outstanding'
  }

  get purchaseOrderNumberText() {
    if (!this.purchaseOrderNumber) {
      return ''
    }
    return ` - #${this.purchaseOrderNumber}`
  }

  get purchaseOrderNumber() {
    return this.purchaseOrder?.purchaseOrderNumber || null
  }

  get total(): number {
    if (this.canViewOpsTotal && this.clientTotalAdjusted) {
      return this.clientTotalAdjusted
    }
    return this.amount
  }

  get paymentStatusClass() {
    if (this.$attrs.paymentStatus === 'not_paid' || this.$attrs.paymentStatus === 'partially_paid') {
      return 'error-msg'
    }
    return ''
  }

  get paymentStatusString() {
    return paymentStatusTypeMap[this.$attrs.paymentStatus]
  }

  get tripContactActions() {
    if (this.tripContactId) {
      return [
        { title: 'View Trip Contact Page', action: this.viewTripContact, id: 0 },
        { title: 'Change/Remove Trip Contact', action: this.editTripContact, id: 2 },
      ]
    } else {
      return [{ title: 'Add Trip Contact', action: this.editTripContact, id: 1 }]
    }
  }

  get requirementsList() {
    const req = []
    if (this.$attrs.isEnterprise) req.push('Enterprise')
    if (this.$attrs.ada) req.push('ADA')
    if (this.$attrs.mountain) req.push('Mountain')
    if (this.$attrs.spab) req.push('SPAB')
    if (this.$attrs.isPreBooking) req.push('Pre-booking')
    return req.join(' | ')
  }

  get paidAmenitiesList() {
    return this.tripAmenities
      ?.filter((amenity: any) => Object.values(paidAmenityTypeIds).includes(amenity.id))
      .map((amenity: any) => amenity.label)
      .join(' | ')
  }

  get referralStatusTypeString() {
    return referralStatusTypeMap[this.$attrs.referralStatus]
  }

  get balancePaymentMethodLabel() {
    if (this.balancePaymentMethods?.length === 0 || this.paymentMethodTypes?.length === 0) {
      return 'N/A'
    }
    const balancePaymentMethod = this.balancePaymentMethods.find(
      (method) => method.isAllowed === 1
    )
    const paymentMethod = this.paymentMethodTypes.find(
      (method) => method.id === balancePaymentMethod.paymentMethodId
    )
    if (paymentMethod) {
      return paymentMethod.label
    }
    return 'N/A'
  }

  get reservationTripInfo() {
    return [
      {
        label: 'Trip Type',
        value: this.tripType,
      },
      {
        label: 'Distance',
        value: this.metersToMilesString(this.distance),
      },
      {
        label: 'Travel Time',
        value: this.secondsToDaysAndHoursString(this.drivingTime),
      },
      {
        label: 'Days',
        value: this.$attrs.calendarDays,
      },
      {
        label: 'Vehicles',
        value: this.requiredVehicles
          ? this.requiredVehicles
              .map(
                (vehicle) => `${vehicle.quantity} ${this.pluralize(vehicle.quantity,vehicle.vehicleType.label)}`)
              .join(', ')
          : [],
      },
      {
        label: 'Drivers',
        value: this.driverCount,
      },
      {
        label: 'Passengers',
        value: this.passengerCount,
      },
    ]
  }

  @Watch('customerId', { immediate: true })
  async onCustomerIdChange(newValue: number) {
    if (newValue) {
      this.customer = await this.getCustomer(newValue)
    }
  }

  @Watch('tripContactId', { immediate: true })
  async onTripContactIdChange(newValue: number) {
    if (newValue) {
      this.tripContact = await this.getCustomer(newValue)
    }
  }

  async mounted() {
    const isCustomerEditEnabled = await this.$store.dispatch(
      'split/isFeatureEnabled', SplitFeatureFlag.MainContactEdit
    )

    if (isCustomerEditEnabled && this.$store.getters['auth/hasPermission']('canUpdateMainContact')) {
      this.customerActions.push({
        title: 'Change Customer',
        action: this.updateCustomer,
        id: 1,
      })
    }
  }

  async getCustomer(customerId: number): Promise<Customer> {
    return (await customers.getCustomer(customerId))?.data?.customer
  }

  editPurchaseOrder() {
    const component = () =>
    import('@/components/PurchaseOrderSidebar.vue')
    this.$store.dispatch('app/openSidebarDialog', {
      data: {
        purchaseOrderNumber: this.purchaseOrderNumber,
        purchaseOrderStatus: this.purchaseOrderStatus,
        purchaseOrderStatuses: this.purchaseOrderStatuses,
        reservationExternalId: this.reservationExternalId,
        title: "PO Status",
      },
      component,
    })
  }

  viewCustomer() {
    if (this.customerId != null) {
      window.open('/customers/' + this.customerId + '/details', '_blank')
    }
  }

  updateCustomer() {
    const component = () =>
    import('@/components/UpdateCustomerSidebar.vue')
    this.$store.dispatch('app/openSidebarDialog', {
      data: { title: 'Customer', managedId: this.managedId, currentCustomer: this.customer },
      component,
    })
  }

  viewCustomerAccount() {
    if (this.customerAccount?.customerAccountId != null) {
      window.open('/customer-accounts/view/' + this.customerAccount.customerAccountId, '_blank')
    }
  }

  editCustomerAccountNotes() {
    const component = () =>
    import('@/components/CustomerAccountNotesSidebar.vue')
    this.$store.dispatch('app/openSidebarDialog', {
      data: { customerAccountId: this.customerAccount?.customerAccountId, title: 'Customer Account Notes' },
      component,
    })
  }

  viewTripContact() {
    if (this.tripContactId != null) {
      window.open('/customers/' + this.tripContactId + '/details', '_blank')
    }
  }

  editTripContact() {
    const tripContact = {
      id: this.tripContact?.userId,
      firstName: this.tripContactFirstName,
      lastName: this.tripContactLastName,
      email: this.tripContactEmail,
      phone: this.tripContactPhone,
      organization: this.tripContact?.organization,
      bookings: this.tripContact?.bookings,
      customerAccount: this.tripContact?.customerAccount,
      isSignedUp: this.tripContact?.isSignedUp,
      forgotPasswordSent: this.tripContact?.forgotPasswordSent
    }
    const component = () =>
    import('@/components/TripContactSidebar.vue')
    this.$store.dispatch('app/openSidebarDialog', {
      data: {
        value: tripContact, tripId: this.tripId, title: 'Trip Contact',
      },
      component,
    })
  }
}
