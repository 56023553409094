
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import DispatchReservationSidebar from '@/components/DispatchReservationSidebar.vue'
import ReservationMapSidebar from '@/components/ReservationMapSidebar.vue'
import ReservationNotes from './ReservationNotes.vue'
import ReservationTrackingMap from './ReservationTrackingMap.vue'
import ReservationComponentLayout from './ReservationComponentLayout.vue'
import ReservationVehicleTrackingIcon from './ReservationVehicleTrackingIcon.vue'
import ReservationTrackingProviderSelector from './ReservationTrackingProviderSelector.vue'
import ReservationTrackingVehicleGroupSelector from './ReservationTrackingVehicleGroupSelector.vue'
import TripModificationRequestToggle from '@/components/TripModificationRequestToggle.vue'
import VehicleEtaColumn from './VehicleEtaColumn.vue'
import SpotTimeButton from './SpotTimeButton.vue'
import StopIcon from './StopIcon.vue'
import { getTripAssignmentsForReservation } from '@/services/reservations'
import drivers from '@/services/drivers'
import { AxiosResponse } from 'axios'
import {
  TrackingDeviceResult,
  Risk,
  Journey,
  JourneyVehicle,
  JourneyStopStatus,
  Stop,
  Note,
  FlightInformation,
  SpotTime,
  Address,
  VehicleAssignment,
  ReferredProvider,
  VehicleType,
  VinVerificationType,
  Company,
  HotelInformationPayload,
  HotelRequirement,
  TripVehicleGroup,
  Driver,
  AmenityType,
  BookedByUser
} from '@/models/dto'
import { includesRisk } from '@/utils/risk'
import eld from '@/services/eld'

import { authComputed } from '@/state/helpers'
import {
  numMinutesBetweenDateTimes,
  ianaZoneToOffsetName,
  timeAgo,
  numDaysBetweenDateTimes,
} from '@/utils/time'
import { EventBus } from '@/utils/event-bus'
import { isAirportAddress } from '@/utils/address'
import {
  RiskTypeId,
  ReservationTrackingAllocation,
  ReservationStatus,
} from '@/utils/enum'
import { addressPretty } from '@/utils/string'
import * as logger from '@/utils/logger'
import { ReservationStopStatus, SplitFeatureFlag } from '@/utils/enum'
import tracking from '@/services/tracking'

type ReservationTrackingTableItem = {
  stopNumber: number
  stopId: number
  address: Address
  timeZone: string
  notes?: string
  stopNotes: Note[]
  risks: Risk[]
  flightInformation: FlightInformation
  isAirportStop: boolean
  actualTime: { [vehicleId: number]: any }
  status: { [vehicleId: number]: string }
  tripVehicleGroupId: number
  spot: SpotTime | {}
  type: string
  pickupDatetime: string
  dropoffDatetime: string
}

type CurrentProviderVehicle = {
  imagePath: string | null
  quantity: number
  tripId?: number
  tripVehicleId?: number
  vehicleId: number
  vehicleMake?: string
  vehicleModel?: string
  vehicleName?: string
  vehicleType: VehicleType
  vehicleTypeId: number
  vehicleTypeKey?: string
  vinNumber?: string
  showOnOperatorPage?: boolean
  vinVerificationType?: VinVerificationType
  simulated?: boolean
  tripVehicleGroupId: number
}

type RiskWarning = {
  riskTypeId?: number
  icon: string
  label: string
  class: string
}

type DriverAssignment = {
  name: string
  userId: number
  companyId: number
  isSignedUp: boolean
  lastLogin: string
  phone: string
}

const PARENT_RESERVATION_TYPE = 0

@Component({
  components: {
    DispatchReservationSidebar,
    ReservationComponentLayout,
    ReservationMapSidebar,
    ReservationNotes,
    ReservationVehicleTrackingIcon,
    VehicleEtaColumn,
    ReservationTrackingMap,
    StopIcon,
    SpotTimeButton,
    TripModificationRequestToggle,
    ReservationTrackingProviderSelector,
    ReservationTrackingVehicleGroupSelector
  },
  computed: {
    ...authComputed,
  },
})
export default class ReservationTracking extends Vue {
  @Prop({ type: Array, required: true }) readonly stops: Stop[]
  @Prop({ type: Number, required: true }) readonly reservationId: number
  @Prop({ type: String, default: '' }) readonly managedId: string
  @Prop({ type: String, default: null }) readonly reservationStatus: string
  @Prop({ type: Number, default: null }) readonly reservationType: number
  @Prop({ type: Boolean, default: false }) readonly active: boolean
  @Prop({ type: String, default: null }) readonly referralStatus: string
  @Prop({ type: Array, default: () => [] }) readonly referredTo: any
  @Prop({ type: Number, default: null }) readonly tripId: number
  @Prop({ type: String, default: null }) readonly tripDescription: string
  @Prop({ type: String, default: null }) readonly tripNotes: string
  @Prop({ type: Array, default: () => [] }) readonly tripNotesList: string[]
  @Prop({ type: Boolean, default: false }) readonly tripVehicleNeededEntireTrip: boolean
  @Prop({ type: Array, default: () => [] }) readonly risks: Risk[]
  @Prop({ type: Array, default: () => [] }) readonly journeys: Journey[]
  @Prop({ type: Object, default: () => {} }) readonly company: Company
  @Prop({ type: Number, default: null }) readonly companyId: number
  @Prop({ type: Array, default: () => [] }) readonly spotTimes: SpotTime[]
  @Prop({ type: Array, default: () => [] }) readonly parentSpotTimes: SpotTime[]
  @Prop({ type: String, default: null }) readonly startDate: string
  @Prop({ type: Object, default: () => null }) readonly hotelInformation: HotelInformationPayload
  @Prop({ type: Object, default: () => null }) readonly hotelRequirement: HotelRequirement
  @Prop({ type: Boolean, default: false }) readonly incompleteItinerary: boolean
  @Prop({ type: Array, default: () => [] }) readonly tripVehicleGroups: TripVehicleGroup[]
  @Prop({ type: String, default: null }) readonly trackedStatus: string
  @Prop({ type: String, default: null }) readonly trackedStatusNote: string
  @Prop({ type: Boolean, default: false }) readonly hasPendingModificationRequest: boolean
  @Prop({ type: String, default: '' }) readonly modificationRequestSubmittedOn: string
  @Prop({ type: Array, default: () => [] }) readonly tripAmenities: AmenityType[]
  @Prop({ type: Object, default: () => {} }) readonly bookedBy: BookedByUser

  debounce = null
  refreshInterval = 0
  expand = false
  headers: {
    text: string
    value: string
    align?: string
    sortable: boolean
  }[] = [
    {
      text: 'Stop',
      value: 'stopNumber',
      align: 'center',
      sortable: false,
    },
    {
      text: 'Type',
      value: 'type',
      sortable: false,
    },
    {
      text: 'Address',
      value: 'address',
      sortable: false,
    },
    {
      text: 'Time',
      value: 'time',
      sortable: false,
    },
    {
      text: 'Actual',
      value: 'actualTime',
      sortable: false,
    },
    {
      text: 'Status',
      value: 'status',
      sortable: false,
    },
    {
      text: 'Notes',
      value: 'notes',
      sortable: false,
    },
  ]
  trackingAllocation: string = null
  trackingReservationStatus: string = null
  trackingTags: {
    [key: string]: {
      [key: string]: string
    }
  } = {
    UPCOMING: {
      NONE: 'Ineligible to Track',
      PARTIAL: 'Partially Eligible to Track',
      ALL: 'Eligible to Track',
    },
    STARTED: {
      NONE: 'Not Tracking',
      PARTIAL: 'Partially Tracking',
      ALL: 'Tracking',
    },
    FINISHED: {
      NONE: 'Not Tracked-On-Time',
      PARTIAL: 'Partially Tracked-On-Time',
      ALL: 'Tracked-On-Time',
    },
  }
  allItineraryItems: ReservationTrackingTableItem[] = []
  items: ReservationTrackingTableItem[] = []
  firstStopDetails: {
    [key: number]: {
      eta: string
      status: string
    }
  } = {}
  journeyStopStatuses: Map<Number, JourneyStopStatus[]> = new Map()
  stopStatusLabels: {
    [vehicleId: number]: {
      [journeyStopId: number]: string
    }
  } = {}
  riskIconMap: {
    [riskTypeId: number]: string
  } = {}
  isDispatchSidebarActive = false
  isMapSidebarActive = false
  trackingVehicles: Set<number> = null
  currentProvider: ReferredProvider = null
  currentVehicleId: number = null
  currentDriver: DriverAssignment = null
  tripAssignments: VehicleAssignment[] = []
  driverAssignments: {
    [vehicleId: number]: DriverAssignment[]
  } = {}
  loadingDriverAssignments = false
  currentProviderVehicles: CurrentProviderVehicle[] = []
  currentVehicleDrivers: DriverAssignment[] = []
  providerDriverCount: number = null
  drivers: Driver[] = []
  vehicles = []
  loadingDriversAndVehicles = false
  addressPretty = addressPretty
  currentTripVehicleGroupId = null
  trackingSummaryPanel = null
  referralTrackingValues = {}
  isAcceleratedPaymentsEnabled = false
  vehicleTrackingSummary = {}
  referralUnassignedVehicles = {}
  isShuttleQuoteAll = false
  isNonShuttleQuoteAll = false
  isLoading = false
  isPaymentTermsDisputesEnabled: boolean = false

  get isShuttleCreateQuoteEnabled(): boolean {
    return this.$store.getters['featureToggles/isShuttleCreateQuoteEnabled']
  }
  get canCreateShuttleQuotes(): boolean {
    return this.$store.getters['auth/hasPermission']('canCreateShuttleQuotes')
  }
  get firstTimezone(): string {
    return this.stops?.filter((stop) => stop?.address?.timeZone)[0]?.address
      ?.timeZone
  }
  get computedStartDate(): string {
    return (
      this.stops?.filter((stop) => stop.pickupDatetime)[0]?.pickupDatetime ||
      this.startDate
    )
  }
  get zonedStartDate(): Date {
    if (this.firstTimezone) {
      return DateTime.fromISO(this.computedStartDate, {
        zone: this.firstTimezone,
      }).toJSDate()
    } else {
      return DateTime.fromISO(this.computedStartDate).toJSDate()
    }
  }

  get today(): Date {
    const currentUser = this.$store.getters['auth/currentUser']
    return DateTime.fromJSDate(new Date())
      .setZone(currentUser.timeZone)
      .toJSDate()
  }

  get endDate(): string {
    return this.stops
      ?.flatMap((s) => [s.pickupDatetime, s.dropoffDatetime])
      .filter((t) => !!t)
      .sort((a, b) => {
        if (DateTime.fromISO(a) > DateTime.fromISO(b)) {
          return 1
        } else if (DateTime.fromISO(a) < DateTime.fromISO(b)) {
          return -1
        } else {
          return 0
        }
      })
      .pop()
  }
  get lastTimezone(): string {
    const filteredStops = this.stops?.filter((stop) => stop.address?.timeZone)
    return filteredStops?.[filteredStops.length - 1]?.address?.timeZone
  }
  get zonedEndDate(): Date {
    if (this.lastTimezone) {
      return DateTime.fromISO(this.endDate, {
        zone: this.lastTimezone,
      }).toJSDate()
    } else {
      return DateTime.fromISO(this.endDate).toJSDate()
    }
  }
  get reservationStartsWithinAnHour(): boolean {
    if (this.today == null || this.zonedStartDate == null) {
      return false
    }
    return numMinutesBetweenDateTimes(this.today, this.zonedStartDate) <= 60
  }
  get reservationStartsWithinThreeHours(): boolean {
    if (this.today == null || this.zonedStartDate === null) {
      return false
    }
    return numMinutesBetweenDateTimes(this.today, this.zonedStartDate) <= 180
  }
  get reservationEndedWithinTwoHoursAgo(): boolean {
    if (this.today == null || this.zonedEndDate === null) {
      return false
    }
    return numMinutesBetweenDateTimes(this.zonedEndDate, this.today) <= 120
  }
  get isReservationStarted(): boolean {
    return this.reservationStatus === 'started'
  }
  get isReservationUpcoming(): boolean {
    return this.reservationStatus === 'upcoming'
  }
  get isReservationFinished(): boolean {
    return this.reservationStatus === 'finished'
  }
  get isPreTripLiveVehicleLocationVisible(): boolean {
    return (
      this.reservationStartsWithinThreeHours &&
      !this.reservationStartsWithinAnHour
    )
  }
  get isLiveVehicleLocationVisible(): boolean {
    return (
      (this.isReservationStarted || this.isReservationUpcoming) &&
      this.reservationStartsWithinAnHour
    )
  }
  get isTrackingVisible(): boolean {
    return (
      this.isPreTripLiveVehicleLocationVisible ||
      this.isLiveVehicleLocationVisible ||
      this.isReservationFinished
    )
  }
  get shouldShowTrackingTag(): boolean {
    if (numDaysBetweenDateTimes(this.today, this.zonedStartDate) > 7) {
      return false
    }
    return true
  }
  get currentVehicleTypeName(): string {
    const currentVehicle = this.vehicles.find(
      (vehicle) => vehicle.vehicleId === this.currentVehicleId
    )
    return currentVehicle?.vehicleTypeName
  }
  get isCurrentVehicleSimulated(): boolean {
    // simulated vehicles won't show up in this.vehicles
    const currentVehicle = this.vehicles.find(
      (vehicle) => vehicle.vehicleId === this.currentVehicleId
    )
    return !currentVehicle
  }
  get isParentReservation(): boolean {
    return this.reservationType === PARENT_RESERVATION_TYPE
  }
  get startDatetime(): string {
    return this.journeys?.find(
      (journey) => journey.vehicleId === this.currentVehicleId
    )?.startDatetime
  }
  get finishDatetime(): string {
    return this.journeys?.find(
      (journey) => journey.vehicleId === this.currentVehicleId
    )?.finishDatetime
  }
  get journeyVehicles(): {
    [key: number]: JourneyVehicle[]
  } {
    return this.journeys?.reduce((vehicles, journey) => {
      const { companyId } = journey.vehicle
      if (!vehicles.hasOwnProperty(companyId)) {
        vehicles[companyId] = []
      }
      vehicles[companyId].push(journey.vehicle)
      return vehicles
    }, {})
  }
  get referredProviders(): any[] {
    let referredProviders = this.referredTo?.filter(
      (ref) => ref.referralStatus === 'accepted'
    )
    referredProviders = referredProviders.map((provider) => {
      let computedName = provider.companyName
      if (
        referredProviders.filter((p) => p.companyId === provider.companyId)
          .length > 1
      ) {
        computedName += ` - ${provider.managedId}`
      }
      return { computedName, ...provider }
    })
    this.isShuttleQuoteAll =
      this.isShuttleCreateQuoteEnabled &&
      referredProviders?.length &&
      this.tripVehicleGroups?.length > 1

    this.isNonShuttleQuoteAll = referredProviders?.length > 1

    if (this.isShuttleQuoteAll || this.isNonShuttleQuoteAll) {
      // Both regular trips and routes trips make use of the 'All' option
      referredProviders = [{ computedName: 'All' }, ...referredProviders]
    }
    return referredProviders
  }
  get filteredReferredProviders() {
    return this.referredProviders.filter((p) => p.computedName !== 'All')
  }
  get vehicleIdList(): number[] {
    return Object.keys(this.journeyVehicles).reduce((vehicleList, provider) => {
      const providerVehicles = this.journeyVehicles[provider].reduce(
        (providerVehicleList, vehicle) => {
          providerVehicleList.push(vehicle.vehicleId)
          return providerVehicleList
        },
        []
      )
      vehicleList.push(...providerVehicles)
      return vehicleList
    }, [])
  }
  get providerVehicleCount(): number {
    return this.currentProviderVehicles?.length
  }
  get requiredVehicleCount(): number {
    return this.currentProvider?.requiredVehicles?.reduce(
      (sum, curr) => sum + curr.quantity,
      0
    )
  }
  get requiredDriverCount(): number {
    return this.currentProvider?.requiredDrivers
  }
  get needsAssignment(): boolean {
    return (
      this.providerDriverCount < this.requiredDriverCount ||
      this.providerVehicleCount < this.requiredVehicleCount
    )
  }
  get currentDriverLastLogin(): string {
    return timeAgo(this.currentDriver.lastLogin)
  }
  get stopRisks(): Risk[] {
    const stopRisks = this.stops?.flatMap((stop) => stop.risks)
    return stopRisks || []
  }
  get itineraryAddressRisk(): boolean {
    const addressRisks = this.stops?.flatMap((stop) => stop.address?.risks)
    return includesRisk(addressRisks, RiskTypeId.AddressRisk)
  }
  get itineraryDriverHotelRisk(): boolean {
    return includesRisk(this.risks, RiskTypeId.DriverHotelRisk)
  }
  get itineraryFlightRisk(): boolean {
    return includesRisk(this.stopRisks, RiskTypeId.FlightRisk)
  }
  get itineraryMissingStopRisk(): boolean {
    return includesRisk(this.stopRisks, RiskTypeId.MissingStopRisk)
  }
  get itineraryDOTComplianceRisk(): boolean {
    return includesRisk(this.risks, RiskTypeId.DOTComplianceRisk)
  }
  get itineraryIncompleteStopRisk(): boolean {
    return includesRisk(this.stopRisks, RiskTypeId.IncompleteStopRisk)
  }
  get itineraryMissingPickupTimeRisk(): boolean {
    return includesRisk(this.stopRisks, RiskTypeId.MissingPickupTimeRisk)
  }
  get isDriverHotelRequired(): boolean {
    return this.hotelRequirement?.isRequired
  }
  get itineraryWarnings(): RiskWarning[] {
    const warnings = []
    if (this.tripVehicleNeededEntireTrip) {
      warnings.push({
        icon: 'bus_alert_warn',
        label: 'Vehicles to remain on-site',
        class: 'error-new',
      })
    }
    if (this.itineraryDriverHotelRisk) {
      warnings.push({
        riskTypeId: RiskTypeId.DriverHotelRisk,
        icon: 'alert_filled',
        label: 'Missing driver hotel',
        class: 'risk-warning',
      })
    }
    if (this.itineraryAddressRisk) {
      warnings.push({
        riskTypeId: RiskTypeId.AddressRisk,
        icon: 'alert_filled',
        label: 'Incomplete address',
        class: 'risk-warning',
      })
    }
    if (this.itineraryFlightRisk) {
      warnings.push({
        riskTypeId: RiskTypeId.FlightRisk,
        icon: 'alert_filled',
        label: 'Missing flight info',
        class: 'risk-warning',
      })
    }
    if (this.itineraryMissingPickupTimeRisk) {
      warnings.push({
        riskTypeId: RiskTypeId.MissingPickupTimeRisk,
        icon: 'alert_filled',
        label: 'Missing pickup time',
        class: 'risk-warning',
      })
    }
    if (this.itineraryMissingStopRisk || this.itineraryIncompleteStopRisk) {
      const riskTypeId = this.itineraryMissingStopRisk
        ? RiskTypeId.MissingStopRisk
        : RiskTypeId.IncompleteStopRisk
      warnings.push({
        riskTypeId,
        icon: 'alert_filled',
        label: 'May have missing stops',
        class: 'risk-warning',
      })
    }
    if (this.itineraryDOTComplianceRisk) {
      warnings.push({
        riskTypeId: RiskTypeId.DOTComplianceRisk,
        icon: 'alert_filled',
        label: 'Confirm DOT compliance',
        class: 'risk-warning',
      })
    }
    return warnings
  }
  get hotelInformationComplete(): boolean {
    if (!this.hotelInformation) {
      return false
    }
    const {
      address,
      hotelName,
      confirmationNumber,
      nameOnReservation,
    } = this.hotelInformation
    return !!(
      address &&
      hotelName &&
      confirmationNumber &&
      nameOnReservation &&
      this.hotelRequirement?.checkIn &&
      this.hotelRequirement?.checkOut
    )
  }
  get showHotelInformation(): boolean {
    return (
      this.hotelInformationComplete &&
      this.isDriverHotelRequired &&
      !this.itineraryDriverHotelRisk
    )
  }
  get currentTripVehicleGroups(): any {
    let tripVehicleGroups = []
    if (this.isShuttleCreateQuoteEnabled) {
      if (this.currentProvider?.tripVehicleGroups) {
        tripVehicleGroups = this.currentProvider.tripVehicleGroups
      } else if (
        this.currentProvider?.computedName === 'All' ||
        !this.referredProviders?.length
      ) {
        tripVehicleGroups = this.tripVehicleGroups
      }
    }
    return tripVehicleGroups
  }
  get currentProviderRouteVehicles(): CurrentProviderVehicle[] {
    if (this.isShuttleCreateQuoteEnabled && this.currentTripVehicleGroupId) {
      return this.currentProviderVehicles.filter(
        (vehicle) =>
          vehicle.tripVehicleGroupId === this.currentTripVehicleGroupId
      )
    }
    return this.currentProviderVehicles
  }
  get showStopStatuses(): boolean {
    return (
      this.trackingReservationStatus === ReservationStatus.Started ||
      this.trackingReservationStatus === ReservationStatus.Finished
    )
  }
  get vehicleIds(): number[] {
    return this.journeys.map((journey) => {
      if (journey?.vehicle?.vehicleId) {
        return journey.vehicle.vehicleId
      }
    })
  }
  get shouldFetchOnTimeStatus(): boolean {
    return (
      this.reservationStartsWithinAnHour &&
      this.reservationEndedWithinTwoHoursAgo
    )
  }
  get allJourneyStops(): Stop[] {
    return this.journeys.reduce((allStops, journey) => {
      if (journey.journeyStops.length > 0) {
        allStops.push(
          ...journey.journeyStops.map((journeyStop) => {
            return { ...journeyStop.stop, reached: journeyStop.reached }
          })
        )
      }
      return allStops
    }, [])
  }

  @Watch('spotTimes')
  onSpotTimesUpdate(newValue: SpotTime[]): void {
    this.items = this.items?.map((item) => {
      item.spot =
        newValue.find((newSpot) => newSpot.stopId === item.stopId) || {}
      return item
    })
    this.refreshInterval += 1
  }

  @Watch('referredTo', { deep: true })
  onReferredToUpdated(): void {
    this.setDefaultCurrentProvider()
  }

  @Watch('stops', { deep: true })
  onStopsUpdate(): void {
    this.setItineraryItems()
  }

  @Watch('currentTripVehicleGroupId')
  onCurrentTripVehicleGroupIdUpdate(): void {
    this.currentVehicleId = this.currentProviderRouteVehicles?.[0]?.vehicleId
    this.setItineraryItems()
    this.setCurrentVehicleDrivers()
    this.setProviderDriverCount()
  }
  @Watch('reservationId')
  onReservationIdUpdate(): void {
    if (this.shouldShowTrackingTag && !this.isReservationFinished) {
      this.setTrackingTag()
    }
  }
  @Watch('trackedStatus')
  onTrackedStatusUpdate(): void {
    if (this.shouldShowTrackingTag && this.isReservationFinished) {
      this.setTrackingTag()
    }
  }
  @Watch('reservationStatus')
  onReservationStatusUpdate(): void {
    if (this.shouldShowTrackingTag && this.isReservationFinished) {
      this.setTrackingTag()
    }
  }

  updated(): void {
    if (
      this.isShuttleCreateQuoteEnabled &&
      this.currentTripVehicleGroups?.length >= 1
    ) {
      this.currentTripVehicleGroupId = this.currentTripVehicleGroups[0].tripVehicleGroupId
    }
  }

  async checkSplitToggles() {
    const [
      isAcceleratedPaymentsEnabled,
      isPaymentTermsDisputesEnabled,
    ] = await Promise.all([
      this.$store.dispatch('split/isFeatureEnabled', SplitFeatureFlag.AcceleratedPayments),
      this.$store.dispatch('split/isFeatureEnabled', SplitFeatureFlag.StopStatusV2Endpoint),
      this.$store.dispatch('split/isFeatureEnabled', SplitFeatureFlag.PaymentTermsDisputes),
    ])

    this.isAcceleratedPaymentsEnabled = isAcceleratedPaymentsEnabled
    this.isPaymentTermsDisputesEnabled = isPaymentTermsDisputesEnabled
  }

  async mounted(): Promise<void> {
    this.isLoading = true
    this.setDefaultCurrentProvider()

    await this.checkSplitToggles()

    if (this.isReservationFinished && this.isAcceleratedPaymentsEnabled) {
      await this.loadTrackingSummary()
    }
    await this.mapRiskTypesToIcons()
    await this.getDriverAssignments()
    if (this.isTrackingVisible) {
      await this.getTrackingVehicles()
      this.debounce = setInterval(() => {
        this.refreshInterval += 1
      }, 240000)
    }
    if (
      this.isShuttleCreateQuoteEnabled &&
      this.currentTripVehicleGroups?.length > 1
    ) {
      this.currentTripVehicleGroupId = this.currentTripVehicleGroups[0].tripVehicleGroupId
    }
    if (this.shouldShowTrackingTag) {
      this.setTrackingTag()
    }
    this.setItineraryItems()
    this.getDriversAndVehicles()

    this.isMapSidebarActive = !!this.$route.params.map
    EventBus.$on('saved-reservation', () => {
      this.isDispatchSidebarActive = false
      this.getDriverAssignments()
    })
    EventBus.$on('reservation-itinerary-update', () => {
      this.refreshInterval++
      if (
        this.isShuttleCreateQuoteEnabled &&
        this.currentProvider?.tripVehicleGroups?.length >= 1
      ) {
        this.currentTripVehicleGroupId = this.currentProvider.tripVehicleGroups[0].tripVehicleGroupId
      }
      this.setItineraryItems()
    })
    this.isLoading = false
  }
  beforeDestroy(): void {
    clearInterval(this.debounce)
    EventBus.$off('saved-reservation')
    EventBus.$off('reservation-itinerary-update')
  }
  openSidebarDialog(context: any): any {
    return this.$store.dispatch('app/openSidebarDialog', context)
  }
  async getTrackingDevicesByVehicleIdsV2(context: {
    requestBody: { vehicleIds: number[] }
    prioritizeEld: boolean
  }): Promise<AxiosResponse<TrackingDeviceResult>> {
    const res = await this.$store.dispatch(
      'trackingDevices/getTrackingDevicesByVehicleIdsV2',
      context
    )
    return res
  }
  openAssignmentSidebar(): void {
    this.isDispatchSidebarActive = true
  }
  async mapRiskTypesToIcons(): Promise<void> {
    const {
      data: { resultList },
    } = await this.$store.dispatch('types/getRiskTypes')
    for (const riskType of resultList) {
      const { riskTypeId } = riskType
      switch (riskTypeId) {
        case RiskTypeId.FlightRisk:
          this.riskIconMap[riskTypeId] = 'airplane'
          break
      }
    }
  }
  async getDriverAssignments(): Promise<void> {
    this.loadingDriverAssignments = true
    const payload = {
      reservationIds: [this.reservationId],
    }
    const vehicleAssignmentsData = await getTripAssignmentsForReservation(
      payload
    )
    this.tripAssignments = vehicleAssignmentsData.data.vehicleAssignments
    this.currentProviderVehicles = []
    this.tripAssignments.forEach((vehicleAssignment) => {
      this.driverAssignments[vehicleAssignment.vehicleId] = []
      vehicleAssignment.driverAssignments.forEach((driverAssignment) => {
        const driver = driverAssignment.driver
        const driverInfo = {
          name: `${driver.firstName} ${driver.lastName}`,
          userId: driver.userId,
          companyId: driver.companyId,
          isSignedUp: driver.isSignedUp,
          lastLogin: driver.lastLogin,
          phone: driver.phone,
        }
        this.driverAssignments[vehicleAssignment.vehicleId].push(driverInfo)
      })
      if (
        (this.currentProvider &&
          vehicleAssignment.reservationId ==
            this.currentProvider.reservationId) ||
        this.currentProvider?.computedName === 'All'
      ) {
        this.currentProviderVehicles.push({
          ...vehicleAssignment.vehicle,
          tripVehicleGroupId: vehicleAssignment.tripVehicleGroupId,
        })
      }
    })
    this.currentVehicleId = this.currentProviderVehicles?.[0]?.vehicleId
    this.setCurrentVehicleDrivers()
    this.setProviderDriverCount()
    this.loadingDriverAssignments = false
  }
  setCurrentVehicleDrivers(): void {
    this.currentVehicleDrivers =
      this.driverAssignments[this.currentVehicleId] ?? []
    this.currentDriver = this.currentVehicleDrivers?.[0]
  }
  setProviderDriverCount(): void {
    let driverCount = 0
    for (const vehicleId in this.driverAssignments) {
      this.driverAssignments[vehicleId].forEach((driver) => {
        if (driver.companyId == this.currentProvider?.companyId) {
          driverCount++
        }
      })
    }
    this.providerDriverCount = driverCount
  }
  async updateCurrentProvider(
    newCurrentProvider: ReferredProvider
  ): Promise<void> {
    this.currentProvider = newCurrentProvider
    await this.getDriverAssignments()
    this.getDriversAndVehicles()
    if (
      this.isShuttleCreateQuoteEnabled &&
      this.currentTripVehicleGroups?.length > 1
    ) {
      this.currentTripVehicleGroupId = this.currentTripVehicleGroups[0].tripVehicleGroupId
    }
  }
  async getDriversAndVehicles(): Promise<void> {
    if (!this.currentProvider?.companyId) {
      return
    }
    this.loadingDriversAndVehicles = true
    await Promise.all([this.getDriverList(), this.getVehicleList()])
    this.loadingDriversAndVehicles = false
  }
  async getDriverList(): Promise<void> {
    const driverData = await drivers.getAllDriversForCompany({
      page: 1,
      pageSize: -1,
      companyId: this.currentProvider.companyId,
    })
    this.drivers = driverData.data.resultList.map((driver) => ({
      ...driver,
      name: `${driver.firstName} ${driver.lastName}`,
    }))
  }
  async getVehicleList(): Promise<void> {
    const vehicleData = await this.$store.dispatch(
      'vehicles/getAllVehiclesForCompany',
      {
        page: 1,
        pageSize: -1,
        companyId: this.currentProvider.companyId,
      }
    )
    this.vehicles = vehicleData.data.resultList.map((vehicle) => ({
      ...vehicle,
      name: `${vehicle.vehicleName} ${vehicle.vehicleModel}`,
    }))
    await this.getVehicleTrackingDevices()
  }
  async getVehicleTrackingDevices(): Promise<void> {
    const vehicleIdList = this.vehicles.map((vehicle) => vehicle.vehicleId)
    await this.getTrackingDevicesByVehicleIdsV2({
      requestBody: { vehicleIds: vehicleIdList },
      prioritizeEld: false,
    })
  }
  async getTrackingVehicles(): Promise<void> {
    const resp = await this.getTrackingDevicesByVehicleIdsV2({
      requestBody: {
        vehicleIds: this.vehicleIdList,
      },
      prioritizeEld: false,
    })
    const trackingDevices = resp.data.devices
    for (const provider in this.journeyVehicles) {
      for (const vehicle of this.journeyVehicles[provider]) {
        const trackedVehicle = trackingDevices.find(
          (trackedVehicle) => trackedVehicle.vehicleId === vehicle.vehicleId
        )
        if (trackedVehicle) {
          vehicle.isTracking = true
          if (!this.trackingVehicles) {
            this.trackingVehicles = new Set()
          }
          this.trackingVehicles.add(vehicle.vehicleId)
        }
      }
    }
  }
  async setTrackingTag(): Promise<void> {
    if (this.isReservationFinished) {
      this.trackingReservationStatus = this.reservationStatus.toUpperCase()
      switch (this.trackedStatus) {
        case 'not_tracked':
          this.trackingAllocation = 'NONE'
          break
        case 'partially_tracked':
          this.trackingAllocation = 'PARTIAL'
          break
        case 'tracked':
          this.trackingAllocation = 'ALL'
          break
        default:
      }
    } else {
      const trackingStatusResult = await this.$store.dispatch(
        'reservations/getReservationTrackingStatuses',
        [this.reservationId]
      )
      this.trackingReservationStatus =
        trackingStatusResult?.data?.trackingStatuses[0]?.reservationStatus
      this.trackingAllocation =
        trackingStatusResult?.data?.trackingStatuses[0]?.trackingAllocation
    }
  }

  showStopNote(item: ReservationTrackingTableItem): void {
    const component = () => import('./StopInfoSidebar.vue')
    this.openSidebarDialog({
      reservationId: this.reservationId,
      data: {
        reservationId: this.reservationId,
        activeStop: item,
        reservationType: this.reservationType,
        title: 'Stop Notes',
        isAirportStop: item.isAirportStop,
        isLastStop: item.stopNumber == this.stops.length,
      },
      component,
    })
  }

  trackedArrivalDisputed(vehicleId) {
    return this.vehicleTrackingSummary[vehicleId].trackedArrivalDisputed
  }

  onTimeDisputed(vehicleId) {
    return this.vehicleTrackingSummary[vehicleId].onTimeDisputed
  }

  trackedOverallDisputed(vehicleId) {
    return this.vehicleTrackingSummary[vehicleId].trackedOverallDisputed
  }

  shouldRowExpand(
    item: ReservationTrackingTableItem,
    currentVehicleId: number
  ): boolean {
    const validDeparture = item.type.includes('Depart') && !this.startDatetime
    const validArrival = item.type.includes('Arrival') && !this.finishDatetime
    const hasVehicleTime =
      currentVehicleId && item?.actualTime?.[currentVehicleId]
    if (validDeparture || validArrival || hasVehicleTime) {
      return true
    }
    return false
  }
  generatedStatusChipClass(status: string): string {
    switch (status) {
      case ReservationStopStatus.ExpectedOnTime:
      case ReservationStopStatus.OnTime:
      case ReservationStopStatus.Arrived:
        return this.$vuetify.theme['gray-dark'].toString()
      case ReservationStopStatus.EnRoute:
        return this.$vuetify.theme['gray-mid-light'].toString()
      case ReservationStopStatus.ExpectedLate:
      case ReservationStopStatus.Late:
      case ReservationStopStatus.NoTracking:
      case ReservationStopStatus.Undetected:
        return this.$vuetify.theme['error-new'].toString()
      default:
        return this.$vuetify.theme['gray-dark'].toString()
    }
  }
  addressToString(address: Address): string {
    return address?.addressName
  }
  editStops(): void {
    this.$router.push({
      name: 'reservation.edit',
      params: { id: this.managedId },
    })
  }
  getJourneyVehicleName(journey: Journey): string {
    const vehicle = this.currentProviderVehicles.find(
      (v) => v.vehicleId === journey.vehicle.vehicleId
    )
    return vehicle?.vehicleName || journey.vehicle?.vehicleName
  }
  async setItineraryItems(): Promise<void> {
    this.items = this.stops?.map((stop, index) => {
      const {
        stopId,
        address,
        notes,
        stopNotes,
        risks,
        flightInformation,
        tripVehicleGroupId,
      } = stop
      const { actualTime, status } = this.groupStopByJourneyVehicles(stopId)
      const isAirportStop = isAirportAddress(address?.addressTypes)
      const newStop = {
        stopNumber: index + 1,
        stopId,
        address,
        timeZone: address.timeZone,
        notes,
        stopNotes,
        risks,
        flightInformation,
        isAirportStop,
        actualTime,
        status,
        tripVehicleGroupId,
        spot: this.spotTimes.find((spot) => spot.stopId === stopId) || {},
        parentSpotTime: this.parentSpotTimes?.find((e) => e.stopId === stopId)
          ?.spotTime,
        type: '--',
        pickupDatetime: stop.pickupDatetime,
        dropoffDatetime: stop.dropoffDatetime,
      }
      if (stop.dropoffDatetime && stop.pickupDatetime) {
        newStop.type = 'Drop-off\nPickup'
      } else if (stop.dropoffDatetime) {
        newStop.type = 'Drop-off'
      } else if (stop.pickupDatetime) {
        newStop.type = 'Pickup'
      }
      return newStop
    })
    this.allItineraryItems = this.items
    if (this.isShuttleCreateQuoteEnabled && this.currentTripVehicleGroupId) {
      this.items = this.items.filter(
        (item) => item.tripVehicleGroupId === this.currentTripVehicleGroupId
      )
    }

    if (this.journeys?.length > 0 && this.items?.length > 0) {
      await this.setStopStatuses()
    }
  }
  async setStopStatuses(): Promise<void> {
    const resp = await this.getTrackingDevicesByVehicleIdsV2({
      requestBody: {
        vehicleIds: this.vehicleIds,
      },
      prioritizeEld: false,
    })
    const allTrackers = resp.data.devices

    const journeyIds = this.journeys.map((journey) => journey.journeyId)
    const journeyIdsToVehicleIds = {}
    for (const journey of this.journeys) {
      journeyIdsToVehicleIds[journey.journeyId] = journey.vehicle.vehicleId
    }
    const stopStatusResp = await eld.getStopStatusesForJourneysV2(journeyIds)
    for (const result of stopStatusResp.data.journeyStatuses) {
      const journeyVehicleId = journeyIdsToVehicleIds[result.journeyId]
      this.journeyStopStatuses.set(journeyVehicleId, result.journeyStopStatuses)
    }
    if (this.shouldFetchOnTimeStatus) {
      try {
        const onTimeResp = await eld.getJourneyOnTimeStatus(journeyIds)
        for (const result of onTimeResp.data.data) {
          const journeyVehicleId = journeyIdsToVehicleIds[result.journeyId]
          this.firstStopDetails[journeyVehicleId] = {
            eta: result.eta,
            status: result.onTimeStatus,
          }
        }
      } catch (e) {
        logger.error(e)
      }
    }

    const statusLabels = {}
    for (const journey of this.journeys) {
      const vehicleId = journey.vehicle.vehicleId
      const journeyStopsForJourney = this.allJourneyStops.filter((js) =>
        journey.stops.some((stop) => stop.stopId === js.stopId)
      )
      const stopStatusesForJourney = this.journeyStopStatuses.get(vehicleId)
      statusLabels[vehicleId] = {}
      for (const journeyStop of journeyStopsForJourney) {
        if (!allTrackers.some((t) => t.vehicleId === vehicleId)) {
          statusLabels[vehicleId][journeyStop.stopId] =
            ReservationStopStatus.NoTracking
          continue
        }

        const status = stopStatusesForJourney?.find(
          (s) => s.orderIndex === journeyStop.orderIndex
        )

        if (!status) {
          statusLabels[vehicleId][journeyStop.stopId] = null
          continue
        }

        const index = stopStatusesForJourney?.indexOf(status)
        if (index === 0) {
          if (status.skippedTimestamp) {
            statusLabels[vehicleId][journeyStop.stopId] =
              ReservationStopStatus.Undetected
            continue
          }
          const firstStopDetails = this.firstStopDetails[vehicleId]
          if (
            this.trackingReservationStatus === ReservationStatus.Started &&
            !status.complete
          ) {
            if (firstStopDetails?.status === 'ON_TIME') {
              statusLabels[vehicleId][journeyStop.stopId] =
                ReservationStopStatus.ExpectedOnTime
            } else if (
              firstStopDetails?.status === ReservationStopStatus.Late
            ) {
              statusLabels[vehicleId][journeyStop.stopId] =
                ReservationStopStatus.ExpectedLate
            } else {
              statusLabels[vehicleId][journeyStop.stopId] =
                ReservationStopStatus.Undetected
            }
          } else if (status.unknown) {
            statusLabels[vehicleId][journeyStop.stopId] =
              ReservationStopStatus.Undetected
          } else if (status.complete && status.arrivedTimestamp) {
            if (
              new Date(status.arrivedTimestamp).getTime() - 1000 * 60 <=
              new Date(status.pickupDatetime).getTime()
            ) {
              statusLabels[vehicleId][journeyStop.stopId] =
                ReservationStopStatus.OnTime
            } else {
              statusLabels[vehicleId][journeyStop.stopId] =
                ReservationStopStatus.Late
            }
          } else if (
            this.trackingReservationStatus === ReservationStatus.Finished
          ) {
            statusLabels[vehicleId][journeyStop.stopId] =
              ReservationStopStatus.Undetected
          }
        } else {
          if (status.skippedTimestamp) {
            statusLabels[vehicleId][journeyStop.stopId] =
              ReservationStopStatus.Undetected
          } else if (status.complete) {
            statusLabels[vehicleId][journeyStop.stopId] =
              ReservationStopStatus.Arrived
          } else if (
            this.trackingReservationStatus === ReservationStatus.Started
          ) {
            if (status.enRouteTimestamp) {
              statusLabels[vehicleId][journeyStop.stopId] =
                ReservationStopStatus.EnRoute
            } else {
              const priorStopStatus = this.journeyStopStatuses.get(
                journey.journeyId
              )?.[index - 1]
              if (
                !(
                  priorStopStatus?.complete ||
                  priorStopStatus?.skippedTimestamp ||
                  priorStopStatus?.unknown
                )
              ) {
                statusLabels[vehicleId][journeyStop.stopId] = null
              } else {
                statusLabels[vehicleId][journeyStop.stopId] = null
              }
            }
          } else {
            statusLabels[vehicleId][journeyStop.stopId] =
              ReservationStopStatus.Undetected
          }

          if (
            this.trackingReservationStatus === ReservationStatus.Started &&
            (statusLabels[vehicleId][journeyStop.stopId] ===
              ReservationStopStatus.Arrived ||
              statusLabels[vehicleId][journeyStop.stopId] ===
                ReservationStopStatus.Undetected ||
              statusLabels[vehicleId][journeyStop.stopId] ===
                ReservationStopStatus.EnRoute)
          ) {
            for (const stopId in statusLabels[journey.journeyId]) {
              if (statusLabels[vehicleId][stopId] === null) {
                statusLabels[vehicleId][stopId] =
                  ReservationStopStatus.Undetected
              }
            }
          }
        }
      }
    }
    this.stopStatusLabels = statusLabels
  }
  groupStopByJourneyVehicles(
    stopId: number
  ): {
    actualTime: { [vehicleId: number]: any }
    status: { [vehicleId: number]: string }
  } {
    const actualTime = {},
      status = {}
    this.journeys.forEach((journey) => {
      const journeyStopIdx = journey.stops.findIndex(
        (journeyStop) => journeyStop.stopId === stopId
      )
      if (journeyStopIdx !== -1) {
        const journeyStop: Stop = journey.stops[journeyStopIdx]
        const journeyJourneyStop = journey.journeyStops.find(
          (journeyStop) => journeyStop.stop.stopId === stopId
        )
        const { vehicleId } = journey.vehicle
        const combinedStopTime = {
          ...journeyStop,
          actualTimeStamp: journeyStop.reachedTimeStamp,
          appVersion: journeyJourneyStop.appVersion,
          automatedCompletion: journeyJourneyStop.automatedCompletion,
          timeZone: this.firstTimezone,
        }
        actualTime[vehicleId] = combinedStopTime
        status[vehicleId] = this.setStopStatusLabel(
          journeyStop,
          journeyStopIdx,
          journey.stops?.[journeyStopIdx - 1]
        )
      }
    })
    return { actualTime, status }
  }
  setStopStatusLabel(stop: Stop, stopIndex: number, prevStop: Stop): string {
    if (stop.reached) {
      return ReservationStopStatus.Completed
    } else if (
      (stopIndex === 0 || prevStop.reached) &&
      this.isReservationStarted
    ) {
      return ReservationStopStatus.EnRoute
    }
    return ReservationStopStatus.ToDo
  }
  getStatusLabel(item: ReservationTrackingTableItem): string {
    if (!this.currentVehicleId) {
      return ''
    }
    return this.stopStatusLabels[this.currentVehicleId]
      ? this.stopStatusLabels[this.currentVehicleId][item.stopId]
      : ''
  }
  formatDisplayTime(time: string, timeZone: string): string {
    if (!time) {
      return '--:--'
    }
    const datetime = DateTime.fromISO(time, { zone: timeZone })
    return `${datetime?.toFormat('M/d/yyyy h:mm a')} ${ianaZoneToOffsetName(
      timeZone, time
    )}`
  }
  getStopTimeLabel(item: ReservationTrackingTableItem): string {
    const statusLabel = this.stopStatusLabels[this.currentVehicleId][
      item.stopId
    ]
    const status = this.journeyStopStatuses
      .get(this.currentVehicleId)
      ?.find((s) => s.stopId === item.stopId)
    if (!status || !statusLabel) {
      return '--:--'
    }

    const timezone =
      this.stops.find((s) => s.stopId === item.stopId)?.address?.timeZone ||
      'America/New_York'
    switch (statusLabel) {
      case ReservationStopStatus.ExpectedOnTime:
      case ReservationStopStatus.ExpectedLate: {
        const firstStopStatus = this.firstStopDetails[this.currentVehicleId]
        return `ETA: ${this.formatDisplayTime(firstStopStatus.eta, timezone)}`
      }
      case ReservationStopStatus.OnTime:
      case ReservationStopStatus.Late:
      case ReservationStopStatus.Arrived:
        return this.formatDisplayTime(status.arrivedTimestamp, timezone)
      case ReservationStopStatus.EnRoute:
        return this.formatDisplayTime(status.enRouteTimestamp, timezone)
      case ReservationStopStatus.Undetected:
        return '--:--'
      default:
        return '--:--'
    }
  }
  hasFlightRisk(stop: ReservationTrackingTableItem): boolean {
    return stop.risks.some((risk) => risk.riskTypeId === RiskTypeId.FlightRisk)
  }
  getRiskIcon(riskTypeId: number): boolean {
    const supportedRiskTypeIds = Object.keys(this.riskIconMap)
    return (
      supportedRiskTypeIds.length !== 0 &&
      supportedRiskTypeIds.includes(String(riskTypeId))
    )
  }
  isWarningClickable(warning: RiskWarning): boolean {
    return warning.riskTypeId === RiskTypeId.DriverHotelRisk
  }
  handleWarningClick(warning: RiskWarning): void {
    if (warning.riskTypeId !== RiskTypeId.DriverHotelRisk) {
      return
    }
    this.openSidebarDialog({
      data: {
        tripId: this.tripId,
        hotelInformation: this.hotelInformation,
        title: 'Driver Hotel Information',
        firstStopDate: this.hotelInformation?.startDate || null,
        lastStopDate: this.hotelInformation?.endDate || null,
      },
      component: () => import('./DriverHotelSidebar.vue'),
    })
  }
  datePretty(date: string): string {
    if (!date) {
      return null
    }
    return DateTime.fromISO(date).toFormat('MMM dd yyyy')
  }
  getTrackingTagTooltipText(
    trackingReservationStatus: string,
    trackingAllocation: string
  ): string {
    if (trackingReservationStatus === ReservationStatus.Upcoming) {
      switch (trackingAllocation) {
        case ReservationTrackingAllocation.All:
          return 'Currently, the vehicle(s) are linked. When this trip starts, we should receive tracking data.'
        case ReservationTrackingAllocation.Partial:
          return 'Currently, some of the vehicle(s) are linked. When this trip starts, we should receive partial tracking data.'
        case ReservationTrackingAllocation.None:
          return 'Currently, the vehicle(s) are not linked to a device. The provider will need to make sure all vehicle(s) are linked and that the device(s) are turned on prior to the trip.'
      }
    } else if (trackingReservationStatus === ReservationStatus.Started) {
      switch (trackingAllocation) {
        case ReservationTrackingAllocation.All:
          return 'The vehicle(s) are linked to a device and we are receiving tracking data.'
        case ReservationTrackingAllocation.Partial:
          return 'Some of the vehicle(s) are linked to a device and we are receiving tracking data.'
        case ReservationTrackingAllocation.None:
          return 'None of the vehicle(s) are linked to a device, or the device(s) are currently off.'
      }
    } else if (trackingReservationStatus === ReservationStatus.Finished) {
      switch (trackingAllocation) {
        case ReservationTrackingAllocation.All:
          return 'The vehicle(s) were linked to a device and we received tracking data.'
        case ReservationTrackingAllocation.Partial:
          return 'Some vehicle(s) were linked to a device and we received tracking data.'
        case ReservationTrackingAllocation.None:
          return 'None of the vehicle(s) were linked to a device and/or we received no tracking data.'
      }
    }
    return ''
  }
  openMapSidebarHandler(): void {
    this.isMapSidebarActive = true
    const path = this.$route.path.replace('/map', '')
    history.replaceState({}, null, `/ci${path}/map`)
  }
  closeMapSidebarHandler(): void {
    this.isMapSidebarActive = false
    const path = this.$route.path.replace('/map', '')
    history.replaceState({}, null, `/ci${path}`)
  }
  setIconColorByVehicle(vehicle: CurrentProviderVehicle): string {
    if (vehicle?.simulated) {
      return 'errorNew'
    }

    return 'grayMediumDark'
  }
  setVehicleClasses(vehicle: CurrentProviderVehicle): string[] {
    if (vehicle?.simulated) {
      return ['text-error-new']
    }
    return []
  }
  formatTime(value: string, zone: string): string {
    if (!value) return ''
    return DateTime.fromISO(value, { zone }).toFormat('h:mm a')
  }
  formatDateTime(time: string, stop: ReservationTrackingTableItem): string {
    if (!time) return '- · -'
    const datetime = DateTime.fromISO(time, { zone: stop.timeZone })
    return `${datetime?.toFormat('M/d/yyyy h:mm a')} ${ianaZoneToOffsetName(
      stop.timeZone, time
    )}`
  }
  setDefaultCurrentProvider() {
    if (!this.isReservationFinished && this.isNonShuttleQuoteAll) {
      this.updateCurrentProvider(this.referredProviders[1])
    } else {
      this.updateCurrentProvider(this.referredProviders[0])
    }
  }
  getJourneysForProvider(companyId) {
    return this.journeys?.filter((j) => j.vehicle.companyId === companyId)
  }
  async loadTrackingSummary() {
    const referralIds = this.referredProviders
      ?.filter((p) => p.computedName !== 'All')
      .map((p) => p.reservationId)
    const response = await tracking.getTrackingSummary(referralIds)
    const trackingSummaries = response?.data?.reservationTrackingSummary
    for (const trackingSummary of trackingSummaries) {
      this.referralUnassignedVehicles[trackingSummary.referralId] =
        trackingSummary.unassignedVehicles
      let fullyTracked = true
      if (trackingSummary.journeyTrackingSummary.length < 1) {
        fullyTracked = false
      }
      for (const summary of trackingSummary.journeyTrackingSummary) {
        this.vehicleTrackingSummary[summary.vehicleId] = summary
        if (
          !(summary.onTime && summary.trackedArrival && summary.trackedOverall)
        ) {
          fullyTracked = false
        }
      }
      this.referralTrackingValues[trackingSummary.referralId] = fullyTracked
    }
  }
}
